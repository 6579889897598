<div
  #filterRow
  (resized)="onResize()"
  class="row"
>
  <div class="search-filter-container">
    @if (showSearch) {
      <dpa-expandable-search
        [placeholderText]="placeholderText"
        (searchChange)="onSearchChange($event)"
        (toggleExpand)="onSearchExpandOrCollapse($event)"
      />
    }
    @if (showFilter) {
      <dpa-filter-with-pills
        [activeFilters]="activeFilters"
        [filterTemplate]="filterTemplate"
        [filterWidth]="filterWidth"
        [isSearchExpanded]="isSearchExpanded"
        [advancedFiltersPillTemplate]="advancedFiltersPillTemplate"
        [advancedFiltersDropdownTemplate]="advancedFiltersDropdownTemplate"
        [showAdvancedFilters]="showAdvancedFilters"
        (removeFilter)="onRemoveFilter($event)"
        (clearAllFilters)="onClearAllFilters()"
        (toggleAdvancedFilters)="onToggleAdvancedFilters($event)"
      />
    }
  </div>
  <div class="sort-date-range-container">
    @if (showTrendDateRangePicker) {
      <dpa-trend-date-range
        [trendDateRange]="trendDateRange"
        (trendDateRangeUpdated)="onUpdateTrendDateRange($event)"
        class="mr-x2"
      />
    }
    @if (showSort) {
      @if (sortOns.length > 2) {
        <div class="sort-container">
          <cds-icon
            shape="sort-by"
            size="16"
          />
          <dpa-combo-box
            [(selected)]="selectedSort"
            [items]="sortOns"
            [formatter]="formatter"
            [isMulti]="false"
            (selectedChange)="onSelectSort($event)"
          />
        </div>
      } @else {
        <div class="btn-group mr-0">
          @for (sortOn of sortOns; track sortOn.by) {
            <div
              (click)="onSelectSort(sortOn)"
              class="radio btn btn-sm"
            >
              <input
                [checked]="selectedSort.by === sortOn.by"
                [id]="sortOn.by"
                type="radio"
              />
              <label [for]="sortOn.by">{{ sortOn.label ?? sortOn.by }}</label>
            </div>
          }
        </div>
      }
    }
  </div>
</div>

<dpa-slide-over
  [isOpen]="isAdvancedFiltersSlideOverActive"
  [size]="FILTER_SLIDE_OVER_SIZE"
  (isOpenChange)="onToggleAdvancedFilters(!isAdvancedFiltersSlideOverActive)"
>
  <dpa-slide-over-header [title]="'COMMON_MESSAGES.FILTERS' | translate" />
  <ng-container *ngTemplateOutlet="advancedFiltersSlideOverTemplate" />
</dpa-slide-over>
