@if (isModalOpen) {
  <dpa-modal
    [modalOpen]="isModalOpen"
    [modalClosable]="!isTestRunning"
    [modalSize]="ModalSize.LG"
    (onModalClose)="closeModal()"
    class="test-action-container"
  >
    <dpa-modal-title class="test-action-modal-header">
      <div
        [attr.data-service-type]="connector?.name"
        [style.background-image]="connector?.iconBackgroundImage"
        class="clr-col-1 dpa-icon automation-action-icon medium round connection-icon"
      ></div>
      <div class="clr-col-11">
        <dpa-ellipsis-with-tooltip>
          {{ connector?.name }} - {{ connectorAction?.name }} - {{ 'COMMON_ACTIONS.TEST' | translate }}
        </dpa-ellipsis-with-tooltip>
      </div>
    </dpa-modal-title>
    <div class="modal-body pb-x2">
      <dpa-alert-banner [target]="alertBannerTarget.MODAL" />
      @if (connector?.status === CONNECTOR_CONFIG_STATUS.FAILED_TO_CONNECT) {
        <clr-alert
          clrAlertType="warning"
          [clrAlertClosable]="true"
        >
          <clr-alert-item class="alert-item">
            <span class="alert-text">
              {{ 'AUTOMATION_WIZARD.CONNECTOR_IS_NOT_CONNECTED' | translate: { connector: connector.name } }}
            </span>
            <div class="alert-actions">
              <a
                (click)="openEditConnectorConfigModal(connector)"
                (keyup.enter)="openEditConnectorConfigModal(connector)"
                class="alert-action"
                target="_blank"
                rel="noopener"
              >
                {{ 'COMMON_ACTIONS.CONNECT' | translate }}
              </a>
            </div>
          </clr-alert-item>
        </clr-alert>
      }
    </div>
    <div
      *ngLet="testAction$ | async as testAction"
      class="modal-body"
    >
      <dpa-accordion>
        @if (testType === ConnectionTestType.AUTOMATION && !unknownTestAttributes?.size) {
          <div
            dpaAccordionRow
            [title]="'COMMON_MESSAGES.RESOLVE_DYNAMIC_VALUES' | translate"
            [expanded]="testAction === connectionModalSection.RESOLVE_DYNAMIC_VALUES"
            (click)="toggleTestSection(connectionModalSection.RESOLVE_DYNAMIC_VALUES)"
            (keyup.enter)="toggleTestSection(connectionModalSection.RESOLVE_DYNAMIC_VALUES)"
          >
            <ng-template dpaAccordionRowContent>
              <div class="test-data-container">
                <clr-alert
                  clrAlertType="info"
                  [clrAlertClosable]="false"
                >
                  <clr-alert-item class="alert-item">
                    <span
                      [innerHTML]="'AUTOMATION_ACTIONS.RESOLVE_DYNAMIC_VALUES_ALERT' | translate"
                      class="alert-text"
                    ></span>
                  </clr-alert-item>
                </clr-alert>
                <div class="card attribute-search">
                  <div class="card-block">
                    <cds-icon shape="search" />
                    <dpa-filter-group-rule
                      [rule]="filterRule"
                      [allColumnsByName]="dynamicValuesColumnsByName"
                      [visibleColumnsSortedByName]="dynamicValuesColumns$ | async"
                      (ruleChange)="onRuleChange($event)"
                    />
                  </div>
                </div>
                <dpa-data-grid
                  *ngLet="dynamicValuesDataGridColumns$ | async as dynamicValuesColumns"
                  [loading]="isDynamicValuesDataLoading$ | async"
                  [selectable]="{ enabled: true, single: true }"
                  [pageSize]="DEFAULT_PAGE_SIZE"
                  [pageSizes]="AVAILABLE_PAGE_SIZES"
                  [response]="dynamicValuesData$ | async"
                  (pageChange)="dynamicValuesPageChange($event)"
                  (selectionChange)="dynamicValuesSelectionChange($event)"
                >
                  @for (dataGridColumn of dynamicValuesColumns; track dataGridColumn) {
                    <div
                      dpaDataGridColumn
                      [field]="dataGridColumn.value"
                      [title]="dataGridColumn.label"
                      [sortable]="false"
                    >
                      <ng-template
                        dpaDataGridCell
                        let-dataItem
                      >
                        <dpa-decorated-value
                          [value]="dataItem[dataGridColumn.value]"
                          [valueDecorator]="dataGridColumn.valueDecorator"
                        />
                      </ng-template>
                    </div>
                  }
                </dpa-data-grid>
                <button
                  (click)="toggleTestSection(connectionModalSection.TEST)"
                  class="btn btn-outline"
                  type="button"
                >
                  {{ 'COMMON_ACTIONS.NEXT' | translate }}
                </button>
              </div>
            </ng-template>
          </div>
        }
        <div
          dpaAccordionRow
          [title]="'COMMON_ACTIONS.TEST' | translate"
          [expanded]="testAction === connectionModalSection.TEST"
          (click)="toggleTestSection(connectionModalSection.TEST)"
          (keyup.enter)="toggleTestSection(connectionModalSection.TEST)"
          class="test-data"
        >
          <ng-template dpaAccordionRowContent>
            @if (testAction === connectionModalSection.TEST) {
              <div class="mark">
                <div class="test-data-container pb-x3">
                  <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
                  @for (section of testableFormSections; track section?.sectionType) {
                    @if (section.isVisible) {
                      <h6>
                        {{ section.sectionType }}
                      </h6>
                    }
                    <dpa-dynamic-form
                      [jsonSchema]="section.schema"
                      [metadata]="section.metadata"
                      [formValues]="replacedDynamicFormValues"
                      (formGroupChange)="automationConnectorActionFormGroupChange(section.sectionType, $event)"
                    />
                  }
                </div>
                <button
                  [disabled]="isTestButtonDisabled"
                  [clrLoading]="isTestRunning"
                  (click)="sendTest()"
                  class="btn btn-success btn-sm mh-x0 mt-x4"
                >
                  {{ (isTestRunning ? 'AUTOMATION_ACTIONS.TESTING' : 'COMMON_ACTIONS.TEST') | translate }}
                </button>
              </div>
            }
          </ng-template>
        </div>
        <div
          *ngLet="testResult$ | async as testResult"
          dpaAccordionRow
          [expanded]="testAction === connectionModalSection.RESULT"
          [disabled]="!testResult?.alert"
          (click)="toggleTestSection(connectionModalSection.RESULT)"
          (keyup.enter)="toggleTestSection(connectionModalSection.RESULT)"
        >
          <ng-template
            dpaAccordionRowHeader
            let-row="row"
          >
            <div class="test-result-header">
              <cds-icon
                [class.rotate-90]="!row.expanded"
                [class.rotate-180]="row.expanded"
                class="section-indicator"
                shape="angle"
                size="16"
              />
              <div class="header-content">
                <div>{{ 'COMMON_MESSAGES.VIEW_TEST_RESULT' | translate }}</div>
                <div
                  *ngLet="testResult$ | async as testResult"
                  class="header-details"
                >
                  @if (testResult) {
                    <div class="clr-row m-x0">
                      <div class="mr-1">{{ 'AUTOMATION_ACTIONS.EXECUTION_STATUS' | translate }}:</div>
                      <div class="execution-status alert alert-{{ testResult.alert }}">
                        {{ testResult.executionStatus | lowercase }}
                      </div>
                    </div>
                    <div class="divider"></div>
                    <div class="clr-row m-x0">
                      <div class="mr-1">{{ 'AUTOMATION_ACTIONS.HTTP_STATUS' | translate }}:</div>
                      <div>{{ testResult.messages?.[0] }}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template dpaAccordionRowContent>
            @if (testResult) {
              <div class="sections-container">
                @if (testResult.alert === ALERT_BANNER_TYPE.SUCCESS) {
                  <div class="alert-wrapper">
                    <clr-alert [clrAlertClosable]="false">
                      <clr-alert-item>
                        <span class="alert-text">
                          {{ 'AUTOMATION_ACTIONS.TEST_CONNECTION_INFO' | translate }}
                        </span>
                      </clr-alert-item>
                    </clr-alert>
                  </div>
                }
                <div class="details-section">
                  <div class="section-body">
                    <ngx-json-viewer [json]="testResult.rawResponse" />
                  </div>
                </div>
              </div>
            }
          </ng-template>
        </div>
      </dpa-accordion>
    </div>
    <div class="modal-footer">
      <button
        [disabled]="isTestRunning"
        (click)="closeModal()"
        class="btn btn-primary"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
    </div>
  </dpa-modal>
}
