@if (!isReadOnly) {
  <div>
    @if (!isPredefinedReport) {
      <dpa-tooltip
        dpaTabEvent
        [attr.aria-disabled]="!shouldRefreshPreview"
        [tooltipText]="(shouldRefreshPreview ? 'COMMON_ACTIONS.REFRESH_PREVIEW' : 'REPORT_CUSTOMIZE.DISABLED_REFRESH_MESSAGE') | translate"
        [attr.aria-label]="
          (shouldRefreshPreview ? 'COMMON_ACTIONS.REFRESH_PREVIEW' : 'REPORT_CUSTOMIZE.DISABLED_REFRESH_MESSAGE') | translate
        "
        (keyup.enter)="onPageChange(pagedRequest)"
        role="button"
      >
        <button
          [disabled]="!shouldRefreshPreview"
          (click)="onPageChange(pagedRequest)"
          class="btn btn-primary btn-sm mt-x2"
        >
          {{ 'COMMON_ACTIONS.REFRESH_PREVIEW' | translate }}
        </button>
      </dpa-tooltip>
    }
    @if (isLoadingPreview) {
      <div class="mv-x1">
        <span>
          {{ 'REPORT_CUSTOMIZE.LOADING_RESULTS' | translate }}
        </span>
      </div>
    }
    @if (totalCount && !isLoadingPreview) {
      <div class="mb-x2 mt-x1">
        <span>
          {{ customPreviewMessage ?? 'REPORT_CUSTOMIZE.REPORT_FILTERS_PREVIEW_RESULTS_COUNT' | translate: { totalCount: totalCount } }}
        </span>
        <dpa-from-now
          [timestamp]="refreshedAt"
          [formatString]="'COMMON_MESSAGES.REFRESHED'"
          class="mt-x2"
        />
      </div>
    }
  </div>
}
<div>
  @if (!isPredefinedReport && isPreviewTimedout) {
    <clr-alert
      clrAlertType="warning"
      [clrAlertClosable]="true"
      class="mt-x2"
    >
      <clr-alert-item class="alert-item">
        <span class="alert-text">
          {{ (isReadOnly ? 'REPORT_CUSTOMIZE.TIMEOUT_WARNING_READONLY' : 'REPORT_CUSTOMIZE.TIMEOUT_WARNING') | translate }}
        </span>
        @if (isReadOnly) {
          <a
            (click)="runReport()"
            (keyup.enter)="runReport()"
          >
            {{ 'COMMON_ACTIONS.RUN' | translate }}
          </a>
        }
      </clr-alert-item>
    </clr-alert>
  }
</div>
<div
  [class.read-only]="isReadOnly"
  class="result-table"
>
  <dpa-report-preview-table
    [loading]="isLoadingPreview"
    [availableColumns]="availableColumns"
    [previewData]="filterResults"
    [cellTemplatesByColumnValue]="cellTemplatesByColumnValue"
    [pagination]="false"
    [enableColumnSelector]="canViewDataGrid() ? !isReadOnly : !dataGridColumns?.length || (!isReadOnly && !isPredefinedReport)"
    [noResultsText]="getNoResultsText() | translate"
    (columnSelection)="onSelectedColumnsChange($event)"
    (pageChange)="onPageChange($event)"
  />
</div>
