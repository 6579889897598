<ng-container *ngLet="hasQuickflowExecutePerm$ | async as hasQuickflowExecutePerm">
  <!-- Currently, dataGridColumns must be in place before mounting -->
  <!-- If not, the change in columns will not be detected -->
  @if (rootCustomAttribute && enableColumnSelector) {
    <button
      (click)="openCustomAttributeSelectorModal()"
      class="btn btn-link"
    >
      {{ 'CUSTOM_ATTRIBUTE.ADD_CUSTOM_ATTRIBUTES' | translate }}
    </button>
  }
  @if (hasQuickflowExecutePerm && hasDeviceIdColumn && canViewDataGrid()) {
    <button
      [disabled]="selectedRows.length === 0"
      (click)="isAutomationSelectSlideOverOpen = true"
      class="btn btn-sm btn-outline"
    >
      {{ 'WORKFLOWS.RUN_QUICKFLOW' | translate }}
    </button>
  }
  @if (canViewDataGrid()) {
    <dpa-data-grid
      [loading]="loading"
      [response]="previewData"
      [sortOns]="sortOns"
      [selectable]="{ single: false, enabled: hasQuickflowExecutePerm && hasDeviceIdColumn }"
      [pageSize]="pageSize"
      [expandedRowTemplate]="expandedRowTemplate"
      [columnWidthByKey]="columnWidthByKey"
      [showDetailView]="showDetailView"
      [detailHeaderTemplate]="detailHeaderTemplate"
      [detailBodyTemplate]="detailBodyTemplate"
      [isExpandFirstRow]="isExpandFirstRow"
      [pagination]="pagination"
      [disableRow]="QuickflowsHelper.isRowDisabled"
      [enableColumnSelector]="enableColumnSelector"
      [enableColumnHiding]="false"
      [selectedRows]="selectedRows"
      [availableColumns]="allColumns"
      [selectedColumns]="dataGridColumns"
      (selectionChange)="onSelectionChange($event)"
      (selectedColumnsChange)="onSelectedColumnsChange($event)"
      (sortChange)="onSortOnsChange($event)"
      (pageChange)="onPageChange($event)"
    >
      @for (tableAction of tableActions; track tableAction) {
        <div
          dpaDataGridSingleRowAction
          [label]="tableAction.labelKey | translate"
          [disabled]="tableAction.isDisabled"
          (click)="tableAction.handler($event)"
          (keyup.enter)="tableAction.handler($event)"
        ></div>
      }
      <div dpaDataGridColumnDefault>
        <ng-template
          dpaDataGridCell
          let-dataItem
          let-column="column"
        >
          @if (cellTemplatesByColumnValue[column.value]) {
            <ng-template
              [ngTemplateOutlet]="cellTemplatesByColumnValue[column.value]"
              [ngTemplateOutletContext]="{ dataItem: dataItem, dataGridColumn: column }"
            />
          }
          @if (!cellTemplatesByColumnValue[column.value]) {
            <dpa-decorated-value
              [value]="dataItem[column.value]"
              [valueDecorator]="column.detail?.valueDecorator"
            />
          }
        </ng-template>
      </div>
      <ng-template
        dpaDataGridColumnSelectorDetail
        let-label
        let-detail="detail"
      >
        <dpa-column-selector-detail
          [label]="label"
          [detail]="detail"
        />
      </ng-template>
    </dpa-data-grid>
  } @else {
    <dpa-data-grid
      [loading]="false"
      [selectable]="{ enabled: false }"
      [showEmptyGridWithColumnHeader]="true"
      [noResultsText]="getNoResultsText() | translate"
      [noResultsSubtext]="noResultsSubtext"
      [enableColumnSelector]="enableColumnSelector"
      [enableColumnHiding]="false"
      [availableColumns]="allColumns"
      [selectedColumns]="dataGridColumns"
      (selectedColumnsChange)="onSelectedColumnsChange($event)"
      class="empty-grid mt-x1"
    >
      <ng-template
        dpaDataGridColumnSelectorDetail
        let-label
        let-detail="detail"
      >
        <dpa-column-selector-detail
          [label]="label"
          [detail]="detail"
        />
      </ng-template>
    </dpa-data-grid>
  }
</ng-container>

@if (isCustomAttributeSelectorModalOpen) {
  <dpa-custom-attribute-selector
    [parentAttributeName]="rootCustomAttribute.attributeName"
    [isMultiSelect]="true"
    [selectedCustomAttributes]="selectedCustomAttributes"
    (selectAttributes)="addCustomAttributeColumns($event)"
    (closeModal)="closeCustomAttributeSelectorModal()"
  />
}

@if (isAutomationSelectSlideOverOpen) {
  <dpa-automation-select-slide-over
    [(isOpen)]="isAutomationSelectSlideOverOpen"
    [automationType]="AutomationType.QUICKFLOWS"
    [title]="'WORKFLOWS.SELECT_A_QUICKFLOW' | translate"
    [isSelectBtnLoading]="isQuickflowExecuting"
    [selectBtnText]="'COMMON_ACTIONS.EXECUTE' | translate"
    [createBtnText]="'WORKFLOWS.ADD_QUICKFLOW' | translate"
    (selectAutomation)="executeQuickflow($event)"
  />
}
